.submit {
  text-align: center;
  justify-content: center;
  width: 50% !important;
  margin-right: 8px;
  border-radius: 4px;
}
.cancel {
  text-align: center;
  justify-content: center;

  width: 50% !important;
  margin-right: 8px;
  border-radius: 4px;
}
.buttonflex {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  text-align: end;
  justify-content: end;
  padding-bottom: 30px;
}

.next {
  text-align: center;
  justify-content: center;
  background-color: rgb(17, 24, 39);
  width: 50% !important;
  margin-right: 8px;
  border-radius: 4px;
}
.next:hover {
  background-color: rgb(31, 47, 83);
}
