table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td {
    text-align: left;
    padding: 8px;
    text-transform: capitalize;
    color:  var(--theme-ternary-text);
    cursor: pointer;
    /* display: flex;
    justify-content: space-between; */
}
/* tr:hover{
    background-color: var(--theme-primary-alpha-20) !important;
} */
th {
    /* border: 1px solid var(--theme-primary); */
    background-color: var(--theme-primary-alpha-80);
    color:  var(--theme-primary-text);
    text-align: left;
    padding: 8px;
    text-transform: capitalize;
    /* display: flex;
    justify-content: space-between; */
}

/* tr:nth-child(odd) {
    background:var(--theme-primary-alpha-5);
} */