.box {
    display: flex !important;
    text-align: center;
    justify-content: center !important;
    align-items: center;
  }

.inputs{
    margin-left: auto;
}


.TimeZone {
    padding: 8px;
    padding-top: 8px;
    padding-bottom: 0px;
    z-index: 6546546546;
    
}
.width50 {
    width: 50% !important;
    display: inline-block;
}

#react-select-4-listbox{
    z-index: 6546546546;
}
